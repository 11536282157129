
import { routeToBusiness, routeToJob } from "@/helpers/routing"
import mapper, { graduationMapping } from "@/helpers/mapping"
import intersectionObserver from "@/mixins/intersectionObserver"

import {
    clock,
    money,
    location,
    handshake,
    education,
    internship,
    icon_university,
} from "@/assets/icons/js"
import Button from "@/components/base/Button"
import Tag from "@/components/base/Tag"
import BusinessLogo from "@/components/business/BusinessLogo"
import ResponsiveVimeo from "@/components/base/video/ResponsiveVimeo"
import Card from "@/components/base/card/Card"
import OPicture from "@/components/base/image/OPicture"

import { mdiChevronRight, mdiChevronLeft, mdiThermostat } from "@mdi/js"

import mappingService from "@/services/mapping.service"
import { FEATURE_TEASER } from "@/enums/jobFeatures"
import { genGradientArray } from "@/helpers/themesHelper"
import media from "@/mixins/media"
import { FEATURE_JOB_CARD_IMAGE } from "@/enums/jobFeatures"
import {
    APPRENTICESHIP_DUAL,
    APPRENTICESHIP_GRAD,
} from "@/enums/apprenticeshipTypes"

export default {
    name: "JobCard",
    components: {
        OPicture,
        Button,
        BusinessLogo,
        Card,
        Tag,
        ResponsiveVimeo,
        JobCardAdminOverlay: () =>
            import("@/components/job/card/JobCardAdminOverlay"),
        JobCardRating: () => import("@/components/job/card/JobCardRating"),
        JobCardChip: () => import("@/components/job/card/JobCardChip"),
    },
    inject: {
        tagColors: {
            from: "tagColors",
            default: undefined,
        },
    },
    mixins: [intersectionObserver, media],
    props: {
        /** job object */
        job: {
            type: Object,
            required: true,
        },
        /** fixed width class applied to the card */
        fixedWidth: {
            type: Boolean,
            default: false,
        },
        targetBlank: {
            type: Boolean,
            default: false,
        },
        embedMode: {
            type: Boolean,
            default: false,
        },
        /** Autoplays video if available */
        autoplay: {
            type: Boolean,
            default: false,
        },
        /** Hides rating functionality */
        hideRating: {
            type: Boolean,
            default: false,
        },
        /** Hides location */
        hideLocation: {
            type: Boolean,
            default: false,
        },
        /** Custom route */
        to: {
            type: [String, Object],
            default: null,
        },
    },
    icons: {
        clock,
        money,
        location,
        handshake,
        education,
        internship,
        icon_university,
        mdiChevronRight,
        mdiChevronLeft,
        mdiThermostat,
    },
    data: () => ({
        isRatingOpen: false,
        isAdminOverlayActive: false,
        intersectionObserverOptions: {
            threshold: 0.75,
        },
    }),
    computed: {
        showJobTags() {
            return (
                !this.hasTraineeship &&
                !this.hasDualStudy &&
                !this.hasGraduationProgram
            )
        },
        hasTraineeship() {
            return this.job.traineeship_data?.is_active
        },
        hasDualStudy() {
            return this.job.apprenticeship?.type_id === APPRENTICESHIP_DUAL
        },
        hasGraduationProgram() {
            return this.job.apprenticeship?.type_id === APPRENTICESHIP_GRAD
        },
        isEinBlick() {
            return this.job.list_features?.includes(FEATURE_TEASER)
        },
        hasDisplayTitleImageFeature() {
            return this.job.list_features?.includes(FEATURE_JOB_CARD_IMAGE)
        },
        showMedia() {
            const hasMedia = this.job.video || this.job.image
            const isNew = Number(this.job.version) === 3
            return (
                (!isNew ||
                    this.isEinBlick ||
                    this.hasDisplayTitleImageFeature) &&
                hasMedia
            )
        },
        displayedJobName() {
            if (this.job.name === "Priester") {
                return `${this.job.name}`
            }
            return `${this.job.name} m/w/d`
        },
        showFirstSlide() {
            let tagsLen = this.job.tags.length
            tagsLen += this.showSchoolTag ? 1 : 0
            tagsLen += this.showBusinessResponseTime ? 1 : 0
            return tagsLen > 1
        },
        showBusinessResponseTime() {
            return !!this.job.business.avg_response_time
        },
        showSchoolTag() {
            if (!this.$can.trainee()) return false
            const schoolId = this.$auth.user.trainee.school?.id
            if (!schoolId) return false
            if (!Array.isArray(this.job.school_ids)) return false
            return this.job.school_ids.includes(schoolId)
        },
        jobDuration() {
            return mappingService.mapJobDurationType(this.job)
        },
        city() {
            return this.job.business_site?.address?.city || "In der Nähe"
        },
        address() {
            const pa = this.job.business_site?.address
            return pa ? `${pa.street}, ${pa.city}` : "In der Nähe"
        },
        details() {
            const detailsArray = []
            /* salary */
            if (this.job.apprenticeship) {
                const keys = [
                    "salary1",
                    "salary2",
                    "salary3",
                    "salary4",
                    "salary5",
                ]
                const salary =
                    this.job.apprenticeship.salaries ||
                    Object.keys(this.job.apprenticeship)
                        .map((key) =>
                            keys.includes(key)
                                ? this.job.apprenticeship[key]
                                : null
                        )
                        .filter((value) => !!value)
                if (Array.isArray(salary) && salary.length > 0) {
                    const min = Math.min(...salary)
                    const max = Math.max(...salary)
                    detailsArray.push({
                        text: min === max ? min : `${min} — ${max}`,
                        icon: this.icons.money,
                    })
                }
            }
            /* required graduation */
            const graduation = this.job.required_graduation
            if (graduation) {
                detailsArray.push({
                    text: mapper.mapValue(graduationMapping)(graduation),
                    icon: this.icons.education,
                })
            }
            /* duration */
            if (this.jobDuration) {
                detailsArray.push({
                    text: `dauert ${this.jobDuration}`,
                    icon: this.icons.clock,
                })
            }
            return detailsArray
        },
        backgroundImage() {
            if (this.job.image) return this.job.image
            const businessImages = this.job.business.main_images
            if (Array.isArray(businessImages) && businessImages.length > 0) {
                const randomIndex = Math.floor(
                    Math.random() * businessImages.length
                )
                return businessImages[randomIndex]
            }
            return this.job.image
        },
        responseTime() {
            if (!this.showBusinessResponseTime) return null
            return mappingService.mapBusinessResponseTime(
                this.job.business.avg_response_time
            )
        },
        responseTimeClass() {
            if (this.showBusinessResponseTime) {
                const oneHour = 60 * 60
                const tenMins = 10 * 60
                const time = 12 * oneHour + tenMins
                if (this.job.business.avg_response_time < time)
                    return "job-card__response-time--green"
            }
            return "job-card__response-time--yellow"
        },
        routeToJob() {
            let query = null
            if (this.embedMode) query = { ref: "frame" }
            const route = routeToJob(this.job, query)
            const resolved = this.$router.resolve(route)
            if (!resolved) return ""
            return resolved.href
        },
        routeToBusiness() {
            const route = routeToBusiness(this.job.business)
            const resolved = this.$router.resolve(route)
            if (!resolved) return ""
            return resolved.href
        },
        jobCardChips() {
            const chipObjects = []
            if (this.showSchoolTag)
                chipObjects.push({
                    id: 1,
                    text: "Partnerfirma deiner Schule",
                    icon: this.icons.handshake,
                    classes: "job-card__school",
                })
            if (this.hasTraineeship)
                chipObjects.push({
                    id: 2,
                    text: "Praktikum möglich",
                    icon: this.icons.internship,
                    classes: "white black--text",
                })
            if (this.hasDualStudy)
                chipObjects.push({
                    id: 4,
                    text: "Duales Studium",
                    icon: this.icons.icon_university,
                    classes: "white black--text",
                })
            if (this.hasGraduationProgram)
                chipObjects.push({
                    id: 5,
                    text: "Abiturientenprogramm",
                    icon: this.icons.education,
                    classes: "white black--text",
                })
            if (this.showBusinessResponseTime && !this.embedMode)
                chipObjects.push({
                    id: 3,
                    text: `Antwortet in ${this.responseTime}h`,
                    icon: this.icons.clock,
                    classes: `job-card__response-time ${this.responseTimeClass}`,
                })
            return chipObjects
        },
    },
    beforeDestroy() {
        const cardEl = this.$refs.card.$el
        // PC events
        cardEl.removeEventListener("mouseenter", this.play, { passive: true })
        cardEl.removeEventListener("mouseleave", this.pause, { passive: true })
        cardEl.removeEventListener("mousemove", this.play, { passive: true })
        // Tablet events
        if (this.$ua.isFromTablet() || this.$ua.isFromPc()) {
            cardEl.removeEventListener("touchstart", this.play)
            document.removeEventListener("touchstart", this.onTouchOutside)
        }
    },
    methods: {
        minimalBackgroundStyles() {
            const gradientColors = genGradientArray(
                this.job.business.logo?.color_palette
            )
            let gradient = "linear-gradient(120deg"
            gradientColors.forEach((color, index) => {
                const colorsLen = gradientColors.length
                const percentage1 = Math.max(0, (index / colorsLen) * 100 - 2)
                const percentage2 = ((index + 1) / colorsLen) * 100
                gradient += `, ${color} ${percentage1}% ${percentage2}%`
            })
            gradient += ")"
            return { background: gradient }
        },
        tagColor(index) {
            if (!this.tagColors) return
            return this.tagColors[index % this.tagColors.length]
        },
        onIntersect() {
            if (!this.$auth.loggedIn || this.$can.trainee())
                this.$oabalytics.trackImpression("job", this.job.id)
        },
        onClick() {
            if (!this.$auth.loggedIn || this.$can.trainee())
                this.$oabalytics.trackEvent(
                    "job_card_click",
                    {
                        id: this.job.id,
                    },
                    "job",
                    this.job.id
                )
        },
        onAdminButtonClick() {
            this.isAdminOverlayActive = !this.isAdminOverlayActive
        },
        onVimeoReady() {
            const cardEl = this.$refs.card.$el
            if (this.$ua.isFromSmartphone())
                this.observeIntersection(
                    cardEl,
                    this.play,
                    this.pause,
                    this.intersectionObserverOptions
                )
            // PC events
            cardEl.addEventListener("mouseenter", this.play, { passive: true })
            cardEl.addEventListener("mouseleave", this.pause, { passive: true })
            cardEl.addEventListener("mousemove", this.play, {
                once: true,
                passive: true,
            })
            // Tablet events
            if (this.$ua.isFromTablet() || this.$ua.isFromPc()) {
                cardEl.addEventListener("touchstart", this.play, {
                    passive: true,
                })
                document.addEventListener("touchstart", this.onTouchOutside, {
                    passive: true,
                })
            }
        },
        play() {
            this.$refs.player?.playVideo()
        },
        pause() {
            this.$refs.player?.pauseVideo()
        },
        onTouchOutside(e) {
            if (e.target !== this.$refs.card.$el) this.pause()
        },
    },
}
