
import colors from "@/mixins/colors"
import { mdiDelete } from "@mdi/js"

export default {
    name: "Tag",
    mixins: [colors],
    props: {
        // index of the tag (for tracking)
        index: {
            type: Number,
            default: 0,
        },
        // track tag clicks
        track: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: "",
        },
        active: {
            type: Boolean,
            default: false,
        },
        brightness: {
            type: Number,
            default: 0.85,
        },
        saturation: {
            type: Number,
            default: 0.8,
        },
        hueFrom: {
            type: Number,
            default: 150,
        },
        hueTo: {
            type: Number,
            default: 300,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        mdiDelete,
    }),
    computed: {
        style() {
            const baseColor =
                this.color ||
                this.stringToRGB(
                    this.tag,
                    this.brightness,
                    this.saturation,
                    this.hueFrom,
                    this.hueTo
                )
            return {
                color: this.active ? "white" : baseColor,
                backgroundColor: this.active ? baseColor : "transparent",
                borderColor: baseColor,
            }
        },
    },
    methods: {
        onClick() {
            if (this.active) return
            this.$emit("click", this.tag)
            this.$oabalytics.trackEvent("search-tag-click", {
                tag: this.tag,
                index: this.index,
            })
        },
    },
}
