import md5 from "js-md5"

export default {
    data() {
        return {
            white: "#FFFFFF",
            black: "#222222",
            primary_gradient: {
                first: "#2BAEE8",
                second: "#D679C7",
            },
            secondary_gradient: {
                first: "#3BDBB6",
                second: "#0BA1DB",
            },
            alert: {
                error: "#DB4436",
                success: "#369D58",
                warn: "#F6A825",
                info: "#3D96F3",
            },
            accents: {
                darkblue: "#3b54ab",
                blue: "#0ba1db",
                lightblue: "#2baee8",
                green: "#38dbb6",
                pink: "#d679c7",
            },
        }
    },
    methods: {
        getSecondary(pos) {
            const values = Object.values(this.accents)
            return values[pos % values.length]
        },
        hexToRGB(h) {
            let r = 0,
                g = 0,
                b = 0

            // 3 digits
            if (h.length == 4) {
                r = "0x" + h[1] + h[1]
                g = "0x" + h[2] + h[2]
                b = "0x" + h[3] + h[3]

                // 6 digits
            } else if (h.length == 7) {
                r = "0x" + h[1] + h[2]
                g = "0x" + h[3] + h[4]
                b = "0x" + h[5] + h[6]
            }

            return { r: +r, g: +g, b: +b }
        },
        intToRGB(i) {
            var c = (i & 0x00ffffff).toString(16).toUpperCase()
            return "00000".substring(0, 6 - c.length) + c
        },
        mapRange(x, in_min, in_max, out_min, out_max) {
            return (
                ((x - in_min) * (out_max - out_min)) / (in_max - in_min) +
                out_min
            )
        },
        rgb2hsv(r, g, b) {
            let v = Math.max(r, g, b),
                c = v - Math.min(r, g, b)
            let h =
                c &&
                (v == r
                    ? (g - b) / c
                    : v == g
                    ? 2 + (b - r) / c
                    : 4 + (r - g) / c)
            return [60 * (h < 0 ? h + 6 : h), v && c / v, v]
        },
        hsv2rgb(h, s, v) {
            let f = (n, k = (n + h / 60) % 6) =>
                v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
            return [f(5), f(3), f(1)]
        },
        stringToRGB(
            string,
            brightness = 0.8,
            saturation = 0.7,
            from = 130,
            to = 300
        ) {
            const md5String = md5(string)
            const md5ShiftIdx = 3
            let hsv = this.rgb2hsv(
                parseInt(
                    md5String.slice(0 + md5ShiftIdx, 2 + md5ShiftIdx),
                    16
                ) / 255,
                parseInt(
                    md5String.slice(2 + md5ShiftIdx, 4 + md5ShiftIdx),
                    16
                ) / 255,
                parseInt(
                    md5String.slice(4 + md5ShiftIdx, 6 + md5ShiftIdx),
                    16
                ) / 255
            )
            let h = this.mapRange(hsv[0], 0, 360, from, to)
            let rgb = this.hsv2rgb(h, saturation, brightness)
            return `rgb(${Math.round(rgb[0] * 255)}, ${Math.round(
                rgb[1] * 255
            )}, ${Math.round(rgb[2] * 255)})`
        },
    },
}
